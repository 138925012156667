import { type ComponentProps } from 'react';
import { type AdContainer as AdContainerInner } from '@motortrend/ids';

import { type AdSizes } from '@/types/AdSizes';

import type Ad from '@/components/Ad';

const fluidAdSizes: AdSizes = {
  desktop: ['fluid'],
  laptop: ['fluid'],
  mobile: ['fluid'],
  tablet: ['fluid'],
};

const topAdhesionAdSizes: AdSizes = {
  desktop: [
    [970, 90],
    [728, 90],
  ],
  laptop: [
    [970, 90],
    [728, 90],
  ],
  mobile: [[320, 50]],
  tablet: [[728, 90]],
};

const mobileHeaderAdTargeting = { fluid: 'mobile_header' };

const mobileFluidSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: ['fluid'],
  tablet: ['fluid'],
};

const topAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const topAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['top', '1'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const middleAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const middleAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['middle', '2'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const bottomAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const bottomAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['bottom', '3'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const dtcLeadsFormAdTarget = { fluid: ['dtcleadsform', 'integrated'] };

const topAdhesionAdTarget = { pos: ['top', '1'] };

const iauAdTargeting = { fluid: 'integrated_showcase' };

const PriceRangeSlug = {
  Over70000: 'over-70000',
  Under20000: 'under-20000',
  Under30000: 'under-30000',
  Under40000: 'under-40000',
  Under50000: 'under-50000',
  Under70000: 'under-70000',
};

const PriceRangeHeaderMap = {
  [PriceRangeSlug.Over70000]: '{bodyStyle} Over $70K',
  [PriceRangeSlug.Under20000]: '{bodyStyle} Under $20K',
  [PriceRangeSlug.Under30000]: '{bodyStyle} $20K-$30K',
  [PriceRangeSlug.Under40000]: '{bodyStyle} $30K-$40K',
  [PriceRangeSlug.Under50000]: '{bodyStyle} $40K-$50K',
  [PriceRangeSlug.Under70000]: '{bodyStyle} $50K-$70K',
};

const PriceRangeMap = {
  [PriceRangeSlug.Over70000]: 'over $70,000',
  [PriceRangeSlug.Under20000]: 'under $20,000',
  [PriceRangeSlug.Under30000]: 'under $30,000',
  [PriceRangeSlug.Under40000]: 'under $40,000',
  [PriceRangeSlug.Under50000]: 'under $50,000',
  [PriceRangeSlug.Under70000]: 'under $70,000',
};

const SeoDescription =
  'What new cars {priceRange} should you buy? With rankings, reviews, and specs of new cars, MotorTrend is here to help you find your perfect car.';

// Namespaced constants to avoid collision
export const PricePageConstants = {
  bottomAdContainerProps,
  bottomAdProps,
  dtcLeadsFormAdTarget,
  fluidAdSizes,
  iauAdTargeting,
  middleAdContainerProps,
  middleAdProps,
  mobileFluidSizes,
  mobileHeaderAdTargeting,
  PriceRangeHeaderMap,
  PriceRangeMap,
  PriceRangeSlug,
  SeoDescription,
  topAdContainerProps,
  topAdhesionAdSizes,
  topAdhesionAdTarget,
  topAdProps,
};
