import { env } from '@/config';
import { ExternalLinks } from '@/constants';

import { Brand } from '@/lib/gql/graphql';

// This will be deprecated in favor of the brandVerticals object in the future
export const vertical = {
  baseUrl: env.baseUrl,
  brandName: 'motortrend',
  cars: 'cars',
  contributorPath: 'staff',
  displayName: 'MotorTrend',
  image: {
    height: 565,
    url: 'https://st.motortrend.com/app/themes/motortrend/mantle/modules/assets/motortrend-logo-share.png',
    width: 1080,
  },
  logoFileName: 'motortrend-og.png',
  twitterHandle: '@motortrend',
  twitterUrl: ExternalLinks.Twitter,
};

export const sameAs = [
  env.motortrendBaseUrl,
  env.hotrodBaseUrl,
  'https://www.facebook.com/motortrend/',
  'https://twitter.com/MotorTrend',
  'https://www.tiktok.com/@motortrend',
  'https://www.pinterest.com/motortrend/',
  'https://news.google.com/publications/CAAiECWMoBt4reCJiRm9DCctzXoqFAgKIhAljKAbeK3giYkZvQwnLc16?hl=en-US&gl=US&ceid=US%3Aen',
  'https://www.instagram.com/motortrend',
  'https://www.youtube.com/user/MotorTrend/',
];

export const defaultAuthorName = 'motortrend staff';

export const brandVerticals = {
  [Brand.Motortrend]: vertical,
  [Brand.Hotrod]: {
    baseUrl: env.hotrodBaseUrl,
    brandName: 'hotrod',
    cars: 'cars',
    contributorPath: 'staff',
    displayName: 'Hot Rod',
    image: {
      height: 565,
      url: 'https://st.motortrend.com/app/themes/motortrend/mantle/modules/assets/motortrend-logo-share.png',
      width: 1080,
    },
    logoFileName: 'hotrod-og.png',
    twitterHandle: '@hotrod',
    twitterUrl: ExternalLinks.Twitter,
  },
} as const;
