const convertObjectValuesToStrings = (obj: {
  [key: string]: number | string | boolean;
}): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = String(obj[key]);
    }
  }
  return result;
};

export default convertObjectValuesToStrings;
