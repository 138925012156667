import { type ComponentProps } from 'react';
import { type AdContainer as AdContainerInner } from '@motortrend/ids';

import { type AdSizes } from '@/types/AdSizes';

import type Ad from '@/components/Ad';

const fluidAdSizes: AdSizes = {
  desktop: ['fluid'],
  laptop: ['fluid'],
  mobile: ['fluid'],
  tablet: ['fluid'],
};

const topAdhesionAdSizes: AdSizes = {
  desktop: [
    [970, 90],
    [728, 90],
  ],
  laptop: [
    [970, 90],
    [728, 90],
  ],
  mobile: [[320, 50]],
  tablet: [[728, 90]],
};

const mobileHeaderAdTargeting = { fluid: 'mobile_header' };

const mobileFluidSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: ['fluid'],
  tablet: ['fluid'],
};

const topAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const topAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['top', '1'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const middleAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const middleAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['middle', '2'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const bottomAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const bottomAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [
      [300, 250],
      [300, 600],
    ],
    laptop: [
      [300, 250],
      [300, 600],
    ],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['bottom', '3'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const dtcLeadsFormAdTarget = { fluid: ['dtcleadsform', 'integrated'] };

const topAdhesionAdTarget = { pos: ['top', '1'] };

const iauAdTargeting = { fluid: 'integrated_showcase' };

// Namespaced constants to avoid collision
export const StaffProfilePageConstants = {
  bottomAdContainerProps,
  bottomAdProps,
  dtcLeadsFormAdTarget,
  fluidAdSizes,
  iauAdTargeting,
  middleAdContainerProps,
  middleAdProps,
  mobileFluidSizes,
  mobileHeaderAdTargeting,
  topAdContainerProps,
  topAdhesionAdSizes,
  topAdhesionAdTarget,
  topAdProps,
};
