import { env } from '@/config';
import { Routes } from '@/constants';
import Hls from 'hls.js';
import { type Config as ReactPlayerConfig } from 'react-player';

import freeveeLogo from '@/assets/images/fasttv/freevee.png';
import googleTvLogo from '@/assets/images/fasttv/google.png';
import plexLogo from '@/assets/images/fasttv/plex.png';
import plutoLogo from '@/assets/images/fasttv/pluto.png';
import rokuLogo from '@/assets/images/fasttv/roku.png';
import samsungLogo from '@/assets/images/fasttv/samsung.png';
import tclLogo from '@/assets/images/fasttv/tcl.png';
import tubiLogo from '@/assets/images/fasttv/tubi.png';
import vidaaLogo from '@/assets/images/fasttv/vidaa.png';
import vizioLogo from '@/assets/images/fasttv/vizio.png';
import xumoLogo from '@/assets/images/fasttv/xumo.png';

const HlsConfig: ReactPlayerConfig = {
  file: {
    attributes: {
      captions: 'false',
    },
  },
  // @ts-expect-error This does not appear to be a valid prop for 'react-player'. We should remove it
  hls: Hls,
};

const EpgBaseWidth = 80;
const EpgBaseHeight = 80;

const ProviderTiles = [
  {
    href: 'https://pluto.tv/en/live-tv/6400f731d200410008f9b339',
    image: plutoLogo,
    name: 'pluto',
  },
  {
    href: 'https://therokuchannel.roku.com/watch/b741b23ecde35d84a9eef29d52cc9ac4/motortrend',
    image: rokuLogo,
    name: 'roku',
  },
  {
    href: 'https://www.amazon.com/gp/video/splash/freevee_findus',
    image: freeveeLogo,
    name: 'freevee',
  },
  {
    href: 'https://blog.google/products/google-tv/google-live-tv-updates/',
    image: googleTvLogo,
    name: 'Google TV',
  },
  {
    href: 'https://watch.plex.tv/live-tv/channel/motortrend-fast-tv',
    image: plexLogo,
    name: 'plex',
  },
  {
    href: 'https://samsungtvplus.page.link/US_STVP_Partners',
    image: samsungLogo,
    name: 'Samsung',
  },
  {
    href: 'https://www.tcl.com/us/en/blog/article/enjoying-live-tv-on-your-tcl-tv',
    image: tclLogo,
    name: 'tcl',
  },
  {
    href: 'https://urldefense.com/v3/__https://tubitv.com/live/400000025?utm_source=tubi&utm_medium=sitepromo&utm_campaign=motortrend_2023fastchannelpromo__;!!AQdq3sQhfUj4q8uUguY!kJZWB9oVzOAu-YHrBqnB9JvKu1IH32EZxvwq7JprFDJL7ewcuhwTREyqvcoPw-G6-MS4Q4LWmAuKaCzEJqDXqdY$',
    image: tubiLogo,
    name: 'tubi',
  },
  {
    href: 'https://www.vidaa.com/',
    image: vidaaLogo,
    name: 'vidaa',
  },
  {
    href: 'https://www.vizio.com/en/watchfreeplus-explore',
    image: vizioLogo,
    name: 'vizio',
  },
  {
    href: 'https://play.xumo.com/networks/motortrend-fast-tv/99951196',
    image: xumoLogo,
    name: 'xumo',
  },
];

const SeoData = {
  canonical: `${env.baseUrl}${Routes.FreeTV}`,
  description:
    'MotorTrend FAST TV is a free ad-supported television channel that offers all of the benefits of streaming live TV without the subscription. Fans enjoy free, easy access to the best of MotorTrend’s content, 24/7.',
  keywords: [
    'fast tv',
    'motortrend plus',
    'motortrend on demand',
    'watch motortrend',
    'motortrend free',
    'stream motortrend',
  ].join(', '),
  title:
    'MotorTrend Fast TV - Watch free MotorTrend shows with no payments and no subscription fees.',
};

// Namespaced constants to avoid collision
export const FastTvConstants = {
  EpgBaseHeight,
  EpgBaseWidth,
  HlsConfig,
  SeoData,
  ProviderTiles,
};
