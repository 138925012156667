const VastLowerEnvChannel = '%26chan%3Dtest';

const VastHomepagePageName = 'homepage';

const DefaultVastTemplate =
  'https://pubads.g.doubleclick.net/gampad/ads?page=[PAGE]&brand=[brand]&ppc=[PPC]&sm_id=[SM_ID]&sect=[SECT]';

const VideoHubVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video/videohub&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=0&vid_d=[DURATION]&plcmt=1&cust_params=k%3DIndex_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]%26playlist%3Dvideohub';

const VideoDetailVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video/videodetail&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=0&vid_d=[DURATION]&plcmt=1&cust_params=k%3DIndex_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]%26playlist%3Dvideodetail';

const AfterMarketVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video_index&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=%7Bvideo.player.width%7D&playerheight=%7Bvideo.player.height%7D&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&cust_params=k%3DIndex_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]';

const HomepageVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video_homepage&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&plcmt=2&cust_params=k%3DHP_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]';

// Buyer's Guide Make & Top Player on MMY
const BGMakeAndTopPlayerOnMmy =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video_buyersguide&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&plcmt=2&cust_params=k%3DIM_BG_video_top%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]%26pos%3Dmidroll';

// Buyer's Guide Index Pages and Bottom player on MMY pages
const BGIndexAndBottomPlayerOnMmy =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video_buyersguide&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&plcmt=2&cust_params=k%3DIM_BG_video%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]';

// content/articles
const ContentAndArticlesVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/video_content&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&plcmt=2&cust_params=k%3DContent_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]%26mpid%3D[mpid]';

const MockVastTag =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1&iu=/3833/motortrend.primedia.com/mock_test&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=motortrend.com&correlator=[timestamp]&playerwidth=[WIDTH]&playerheight=[HEIGHT]&ad_rule=1&cmsid=2556085&vid=[ID]&vpmute=1&vid_d=[DURATION]&cust_params=k%3DIndex_videoad%26brand%3D[brand]%26page%3D[PAGE]%26sm_id%3D[SM_ID]%26ppc%3D[PPC]%26sect%3D[SECT]';

// Namespaced constants to avoid collision
export const VastTemplateConstants = {
  AfterMarketVastTag,
  BuyersGuideHomepageVastTag: BGIndexAndBottomPlayerOnMmy,
  CarRankingsPageVastTag: BGIndexAndBottomPlayerOnMmy,
  ContentAndArticlesVastTag,
  DefaultVastTemplate,
  HomepageVastTag,
  MakePageVastTag: BGMakeAndTopPlayerOnMmy,
  MakeBodyStyleVastTag: BGMakeAndTopPlayerOnMmy,
  MakeAndModelHeroVideoVastTag: BGMakeAndTopPlayerOnMmy,
  MmyAdConfigVastTag: BGIndexAndBottomPlayerOnMmy,
  MockVastTag,
  PricePageVastTag: BGIndexAndBottomPlayerOnMmy,
  VastHomepagePageName,
  VastLowerEnvChannel,
  VideoDetailVastTag,
  VideoHubVastTag,
};
