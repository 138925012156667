import { type ComponentProps } from 'react';
import { type AdContainer as AdContainerInner } from '@motortrend/ids';

import { AdSizes } from '@/types/AdSizes';

import getCertifiedUrl from '@/utils/getCertifiedUrl';
import Ad from '@/components/Ad';

const BottomAdContainerProps: ComponentProps<typeof AdContainerInner> = {
  className: 'flex min-h-[250px] min-w-[300px] lg:mb-0 lg:hidden',
};

const BottomAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['bottom', '3'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const SeoData = {
  description: `Three Reasons to buy MotorTrend Certified: save money on a quality used car, certified vehicles are backed by the manufacturers with a certified warranty and inspection list, and they come with a vehicle history report and roadside assistance.`,
  title: `MT Certified Program`,
  keywords: [
    'certified cars',
    'cpo cars',
    'motortrend certified',
    'certified pre-owned cars',
  ].join(', '),
};

const FluidAdSizes: AdSizes = {
  desktop: ['fluid'],
  laptop: ['fluid'],
  mobile: ['fluid'],
  tablet: ['fluid'],
};

const DtcLeadsFormAdTarget = { fluid: ['dtcleadsform', 'integrated'] };

const topAdhesionAdSizes: AdSizes = {
  desktop: [
    [970, 90],
    [728, 90],
  ],
  laptop: [
    [970, 90],
    [728, 90],
  ],
  mobile: [[320, 50]],
  tablet: [[728, 90]],
};

const topAdhesionAdTarget = { pos: ['top', '1'] };
const TopAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['top', '1'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const mobileTopAdSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: [[320, 50]],
  tablet: [
    [320, 50],
    [728, 90],
  ],
};

const desktopTopAdSizes: AdSizes = {
  desktop: [
    [970, 90],
    [728, 90],
  ],
  mobile: [],
  tablet: [[728, 90]],
};

export const CertifiedConstants = {
  BottomAdProps,
  BottomAdContainerProps,
  topAdhesionAdSizes,
  topAdhesionAdTarget,
  SeoData,
  FluidAdSizes,
  DtcLeadsFormAdTarget,
  TopAdProps,
  mobileTopAdSizes,
  desktopTopAdSizes,
};

export const generateBodyStyleItems = (zipCode: string = '') => {
  return [
    {
      displayName: 'Convertible',
      slug: 'convertible',
      to: getCertifiedUrl('Convertible', zipCode),
    },
    {
      displayName: 'Coupe',
      slug: 'coupe',
      to: getCertifiedUrl('Coupe', zipCode),
    },
    {
      displayName: 'Wagon',
      slug: 'hatchback',
      to: getCertifiedUrl('Wagon', zipCode),
    },
    {
      displayName: 'Hatchback',
      slug: 'hatchback',
      to: getCertifiedUrl('Hatchback', zipCode),
    },
    {
      displayName: 'Minivan',
      slug: 'van',
      to: getCertifiedUrl('Van%2FMinivan', zipCode),
    },
    {
      displayName: 'SUV',
      slug: 'suv',
      to: getCertifiedUrl('SUV%2FCrossover', zipCode),
    },
    {
      displayName: 'Sedan',
      slug: 'sedan',
      to: getCertifiedUrl('Sedan', zipCode),
    },
    {
      displayName: 'Pickup',
      slug: 'truck',
      to: getCertifiedUrl('Pickup', zipCode),
    },
  ];
};
