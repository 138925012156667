import { MOTORTREND_MARKETPLACE_URL } from './dtcWidget/constants';
import { normalizeUrl } from './normalizeUrl';

const getCertifiedUrl = (type: string, zipCode: string): string => {
  return normalizeUrl({
    base: MOTORTREND_MARKETPLACE_URL,
    paths: ['search-results'],
    queryParams: {
      bodytypes: type,
      mtc: true,
      zip: zipCode,
    },
  });
};

export default getCertifiedUrl;
