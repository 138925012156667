import { env } from '@/config';

export const MINIMUM_DTC_LISTING_COUNT = 4;
export const MOTORTREND_MARKETPLACE_URL = env.marketplace.href;

export const pageNames = {
  bghp: 'BGHP',
  carRankings: 'carRankings',
  make: 'make',
  mmy: 'MMY',
  price: 'price',
  makeStyle: 'makeStyle',
};
