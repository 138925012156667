import { type ComponentProps } from 'react';

import { AdSize } from '@/types/AdSizes';

import type Ad from '@/components/Ad';

const SeoData = {
  description: `What new {category} should you buy? With rankings, ratings reviews, and specs of new {category}s, MotorTrend is here to help you find your perfect car.`,
  title: `Best {category}s for 2024 and 2025 - Top Rated {category} Models | MotorTrend`,
};

const TopAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['top', '1'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const MiddleAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [],
    laptop: [],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['middle', '2'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const MobileHeaderAdTargeting = { fluid: 'mobile_header' };

const BottomAdProps: ComponentProps<typeof Ad> = {
  className: 'm-auto w-fit',
  sizes: {
    desktop: [
      [300, 250],
      [300, 600],
    ],
    laptop: [
      [300, 250],
      [300, 600],
    ],
    mobile: [[300, 250]],
    tablet: [[300, 250]],
  },
  targeting: {
    native: '300x250',
    ntvPlacement: '1115836',
    pos: ['bottom', '3'],
  },
  pageTargeting: {
    adPath: '',
    adTargeting: {},
    defaultVastTemplate: '',
  },
};

const FluidAdSize: AdSize[] = ['fluid'];
const DtcLeadsFormAdTarget = { fluid: 'dtcleadsform, integrated' };
const MobileMrecAdSize: AdSize[] = [[300, 250]];

const DesktopFluidSizeMap: googletag.SizeMappingArray = [
  [[1024, 0], ['fluid']], // Desktop
  [[0, 0], []], // Mobile (no ads)
];

const MobileFluidSizeMap: googletag.SizeMappingArray = [
  [[1024, 0], []], // Desktop (no ads)
  [[0, 0], ['fluid']], // Mobile
];

const MobileAdSizeMap: googletag.SizeMappingArray = [
  [[1024, 0], []], // Desktop (no ads)
  [
    [0, 0],
    [300, 250],
  ], // Mobile
];

// Namespaced constants to avoid collision
export const CarRankingsConstants = {
  BottomAdProps,
  DesktopFluidSizeMap,
  DtcLeadsFormAdTarget,
  MobileMrecAdSize,
  MobileAdSizeMap,
  MobileFluidSizeMap,
  FluidAdSize,
  MiddleAdProps,
  MobileHeaderAdTargeting,
  SeoData,
  TopAdProps,
};
