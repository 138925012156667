enum Template {
  BuyersGuideHome = 'BuyersGuideHome',
  Make = 'Make',
  MakeModelYear = 'MakeModelYear',
  MakeBodyStyle = 'MakeBodyStyle',
}

// Namespaced constants to avoid collision
export const PhotoMosaicConstants = {
  Template,
};
