import { env } from '@/config';
import { NewslettersStrings as strings } from '@/translations';

import { Newsletter } from '@/types/Newsletters';

import { DataId } from '@/utils/nitrous/constants';

import HotRodImage from '@/assets/images/newsletters/hotrod.jpg';
import MotorTrendImage from '@/assets/images/newsletters/motortrend.jpg';

import { Routes } from './routes';

const newsletters: Newsletter[] = [
  {
    dataId: DataId.NewslettersAddMotorTrend,
    description: strings.NewsletterDescription.MotorTrend,
    id: 23,
    image: MotorTrendImage,
    name: 'motortrend_newsletter_subscription',
    title: 'MotorTrend',
  },
  {
    dataId: DataId.NewslettersAddHotRod,
    description: strings.NewsletterDescription.HotRod,
    id: 3498,
    image: HotRodImage,
    name: 'hotrod_newsletter_subscription',
    title: 'HOT ROD',
  },
];

const SeoData = {
  canonical: `${env.baseUrl}${Routes.Newsletters}`,
  title: `MotorTrend Group Newsletters: MotorTrend and HOT ROD`,
  description: `Trust MotorTrend for the best new car reviews and tests, the latest auto news. HOT ROD has advocated for customizing, racing, and wrenching.`,
  keywords: ['motortrend newsletter', 'hotrod newsletter'],
  image: {
    url: 'https://st.motortrend.com/app/themes/motortrend/mantle/modules/assets/motortrend-logo-share.png',
    width: 1080,
    height: 565,
  },
};

export const NewslettersConstants = {
  SeoData,
  newsletters,
};
